<script>
import modalMixin from '@phoenix/mixins/modalMixin';
import Modal from '@maison/components/common/Modal.vue';
import DefaultView from './views/DefaultView';
import SelectWatchView from './views/SelectWatchView';
import EnterReferenceView from './views/EnterReferenceView';
import ReferenceGuideView from './views/ReferenceGuideView';
import { mapActions, mapState } from 'vuex';
import { get } from 'lodash-es';

export default {
  components: {
    Modal,
    DefaultView,
    SelectWatchView,
    EnterReferenceView,
    ReferenceGuideView,
  },

  mixins: [modalMixin],

  props: {
    references: {
      type: Array,
      required: true,
    },
    engravingToolUrl: {
      type: String,
      required: true,
    },
    selectionItems: {
      type: Array,
      default: () => [],
    },
    isOnEngravingToolPage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectWatchViewTitle: '',
      displayOnlySelectWatch: false,
      isClosable: true,
    };
  },

  computed: {
    ...mapState('engravingToolModal', ['currentView', 'views']),

    title() {
      return this.currentView === this.views.SELECT_WATCH && this.selectWatchViewTitle !== ''
        ? this.selectWatchViewTitle
        : this.$t(`EngravingTool.${this.currentView}.title`);
    },

    defaultView() {
      return this.displayOnlySelectWatch ? this.views.SELECT_WATCH : this.views.DEFAULT;
    },

    modalOptions() {
      return {
        onShow: (event) => {
          this.setProps(this.getModalProps(event));

          this.setData({
            references: this.references,
            selectionItems: this.selectionItems,
            displayOnlySelectWatch: this.displayOnlySelectWatch,
            isOnEngravingToolPage: this.isOnEngravingToolPage,
            engravingToolUrl: this.engravingToolUrl,
          });

          this.setView({ view: this.defaultView });
        },
      };
    },
  },

  methods: {
    ...mapActions('engravingToolModal', ['setView', 'setData']),

    setProps(props) {
      this.selectWatchViewTitle = get(props, 'selectWatchViewTitle', '');
      this.displayOnlySelectWatch = get(props, 'displayOnlySelectWatch', false);
      this.isClosable = get(props, 'isClosable', !this.isOnEngravingToolPage);
    },
  },
};
</script>

<template>
  <modal
    :id="id"
    :classes="['engraving-modal', 'modal--overflow']"
    :title="title"
    :options="modalOptions"
    :is-closable="isClosable"
    data-cy="engraving-tool-selection-modal"
    header-bordered
  >
    <template #content>
      <component :is="currentView" @close="close" />
    </template>
  </modal>
</template>
