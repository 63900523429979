<script>
import PictureLazy from '@maison/components/common/PictureLazy';
import { PhxIcon } from '@phx-styleguides/jlc';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    PictureLazy,
    PhxIcon,
  },

  emits: ['close'],

  data() {
    return {
      sliderId: 'engraving-tool-select-watch-slider',
    };
  },

  computed: {
    ...mapState('engravingToolModal', [
      'selectionItems',
      'views',
      'displayOnlySelectWatch',
      'isOnEngravingToolPage',
      'engravingToolUrl',
    ]),

    sliderClass() {
      return ['slider engraving-modal__slider', { 'display-only-select-watch': this.displayOnlySelectWatch }];
    },
  },

  mounted() {
    const sliderElement = this.$refs.slider;
    if (sliderElement) {
      Toolkit.slider(sliderElement, {
        slidesPerView: 'auto',
        spaceBetween: 20,
        breakpoints: {
          768: {
            slidesPerView: 3,
          },
        },
      });
    }
  },

  methods: {
    ...mapActions('engravingToolModal', ['setView', 'setReference']),

    getSearchEngravingDataTracking() {
      return JSON.stringify(window.tracking.getEngravingSearchReferenceTracking());
    },

    getReferenceDataTracking(itemId, itemName) {
      return JSON.stringify(window.tracking.getEngravingSelectReferenceTracking(itemId, itemName));
    },

    onSelectItem(item) {
      this.setReference({ reference: item.reference, referenceType: 'NR' });
      this.$emit('close');
    },

    getEngravingToolLink(item) {
      const url = new URL(this.engravingToolUrl);
      url.searchParams.set('reference', item.reference);
      return url.toString();
    },
  },
};
</script>

<template>
  <div>
    <div class="h4">{{ $t('EngravingTool.selectWatchView.subtitle') }}</div>

    <div
      :id="sliderId"
      ref="slider"
      :class="sliderClass"
      role="group"
      aria-roledescription="carousel"
      aria-label="A selection of product images"
    >
      <button type="button" class="btn btn--default btn--icon-only btn--icon slider__nav slider__nav--prev">
        <span class="sr-only">{{ $t('Button.previousSlide') }}</span>

        <span class="btn__icon">
          <phx-icon icon="chevron-16" bidirectional />
        </span>
      </button>

      <div class="slider__body" aria-atomic="false" aria-live="polite">
        <div
          v-for="(item, key) in selectionItems"
          :id="`${sliderId}-slide-${key}`"
          :key="key"
          class="slider__item"
          role="tabpanel"
        >
          <div class="product-card">
            <div class="product-card__picture">
              <div class="picture">
                <picture-lazy v-bind="item.image" />
              </div>
            </div>

            <div class="product-card__body">
              <component
                :is="isOnEngravingToolPage ? 'button' : 'a'"
                :href="isOnEngravingToolPage ? null : getEngravingToolLink(item)"
                class="product-card__link expand-target text-left"
                :data-tracking="getReferenceDataTracking(item.reference, item.title)"
                :data-cy="`select-watch-${key}`"
                @click="isOnEngravingToolPage ? onSelectItem(item) : null"
              >
                <div class="product-card__details">
                  <h5 class="product-card__collection">{{ item.title }}</h5>

                  <div class="product-card__text-link">
                    <div class="btn btn--link btn--icon product-card__details-link">
                      <span class="btn__text">
                        {{ $t('EngravingTool.selectWatchView.personalizeCta') }}
                      </span>

                      <span class="btn__icon">
                        <phx-icon icon="chevron-10" bidirectional />
                      </span>
                    </div>
                  </div>
                </div>
              </component>
            </div>
          </div>
        </div>
      </div>

      <button type="button" class="btn btn--default btn--icon-only btn--icon slider__nav slider__nav--next">
        <span class="sr-only">{{ $t('Button.nextSlide') }}</span>

        <span class="btn__icon">
          <phx-icon icon="chevron-16" bidirectional />
        </span>
      </button>

      <div class="slider__footer">
        <div class="slider__scrollbar"></div>
      </div>
    </div>

    <div v-if="!displayOnlySelectWatch" class="engraving-modal__link">
      <button
        class="btn btn--link btn--icon"
        :data-tracking="getSearchEngravingDataTracking()"
        @click="setView({ view: views.ENTER_REFERENCE })"
      >
        <span class="btn__text">{{ $t('EngravingTool.defaultView.enterRefCta') }}</span>
        <span class="btn__icon">
          <phx-icon icon="chevron-10" bidirectional />
        </span>
      </button>
    </div>
  </div>
</template>

<style scoped>
.display-only-select-watch {
  margin-bottom: 0 !important;
}
</style>
