<script>
import PictureLazy from '@maison/components/common/PictureLazy';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    PictureLazy,
  },

  data() {
    return {
      image: {
        image: {
          src: '/jlc/images/strap-finder/acknowledge.png',
        },
        lazy: true,
      },
    };
  },

  computed: {
    ...mapState('engravingToolModal', ['views']),
  },

  methods: {
    ...mapActions('engravingToolModal', ['setView']),
  },
};
</script>

<template>
  <div class="aknowledge-modal__content justify-between">
    <div class="aknowledge-modal__content-media">
      <div class="picture">
        <picture-lazy v-bind="image" />
      </div>
    </div>
    <div class="aknowledge-modal__content-text flex flex-col justify-between">
      <div>{{ $t('StrapSearch.guidanceModal.contentText') }}</div>

      <ul class="modal__buttons-list" role="presentation">
        <li>
          <button class="btn btn--link link--accent" @click="setView({ view: views.ENTER_REFERENCE })">
            <span class="btn__text">{{ $t('StrapSearch.acknowledgeModal.btnBackToReference') }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
