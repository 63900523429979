<script>
import { mapActions, mapState } from 'vuex';
import { vMaska } from 'maska';
import { PhxAutocomplete, PhxIcon } from '@phx-styleguides/jlc';

export default {
  components: {
    PhxAutocomplete,
    PhxIcon,
  },

  directives: { maska: vMaska },

  emits: ['close'],

  data() {
    return {
      reference: null,
      query: '',
    };
  },

  computed: {
    ...mapState('engravingToolModal', ['views', 'references', 'isOnEngravingToolPage', 'engravingToolUrl']),

    autocompleteSuggestions() {
      return this.references
        .filter((reference) => reference.toLowerCase().includes(this.query.toLowerCase()))
        .map((reference) => ({
          key: reference,
          value: reference,
        }));
    },
  },

  methods: {
    ...mapActions('engravingToolModal', ['setView', 'setReference']),

    submit() {
      if (this.reference === null) {
        return;
      }

      if (this.isOnEngravingToolPage) {
        this.setReference({ reference: this.reference, referenceType: 'YR' });
        this.$emit('close');
      } else {
        const url = new URL(this.engravingToolUrl);
        url.searchParams.set('reference', this.reference);
        window.location.href = url.toString();
      }
    },

    getCheckReferenceDataTracking() {
      return JSON.stringify(window.tracking.getEngravingSearchReferenceTracking());
    },

    getFindReferenceDataTracking() {
      return JSON.stringify(window.tracking.getEngravingFindReferenceTracking());
    },

    onAutocompleteQuery(query) {
      query = query.replaceAll(',', '.');
      this.query = query;
      if (this.references.includes(query)) {
        this.reference = query;
      }
    },

    onAutocompleteChange(query) {
      const reference = query?.value ?? query;
      if (this.references.includes(reference)) {
        this.reference = reference;
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="enter-ref-modal__content">
      <div class="h4">{{ $t('EngravingTool.enterReferenceView.description') }}</div>

      <div class="enter-ref-modal__form">
        <phx-autocomplete
          id="enter-ref-autocomplete"
          name="enter-ref-autocomplete"
          clear-button-icon="cross-16"
          open-button-icon="chevron-down-16"
          :label="$t('Form.label.reference')"
          :clear-button-label="$t('StrapSearch.clearLabel')"
          :open-button-label="$t('StrapSearch.openLabel')"
          :no-result-text="$t('StrapSearch.noResultsLabel')"
          :loading-text="$t('StrapSearch.loading')"
          :placeholder="$t('EngravingTool.enterReferenceView.placeholder')"
          :options="autocompleteSuggestions"
          hide-label
          @autocomplete:query="onAutocompleteQuery"
          @autocomplete:change="onAutocompleteChange"
        />

        <button
          type="button"
          class="btn btn--default btn--icon btn btn--default btn--icon btn--negative enter-ref-modal__btn"
          :data-tracking="getCheckReferenceDataTracking()"
          @click="submit"
        >
          <span class="btn__text">
            {{ $t('StrapSearch.enterRefModal.btnCheckMyReference') }}
          </span>
          <span class="btn__icon">
            <phx-icon icon="arrow-16" class="icon--sm" bidirectional />
          </span>
        </button>
      </div>

      <ul class="enter-ref-modal__form-links-list">
        <li>
          <button
            class="btn btn--link link--accent"
            :data-tracking="getFindReferenceDataTracking()"
            @click="setView({ view: views.REFERENCE_GUIDE })"
          >
            <span class="btn__text">
              {{ $t('StrapSearch.enterRefModal.btnWhereToFindMyReference') }}
            </span>
          </button>
        </li>

        <li>
          <button
            class="btn btn--link btn--icon btn btn--default btn--icon"
            @click="setView({ view: views.SELECT_WATCH })"
          >
            <span class="btn__text">
              {{ $t('EngravingTool.enterReferenceView.selectWatchCta') }}
            </span>

            <span class="btn__icon">
              <phx-icon icon="arrow-16" bidirectional />
            </span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
