<script>
import { mapActions, mapState } from 'vuex';
import { PhxIcon } from '@phx-styleguides/jlc';

export default {
  components: {
    PhxIcon,
  },

  computed: {
    ...mapState('engravingToolModal', ['views']),
  },

  methods: {
    ...mapActions('engravingToolModal', ['setView']),

    getDataTracking(cta) {
      return JSON.stringify(window.tracking.getStartingEngravingTracking(cta));
    },
  },
};
</script>

<template>
  <div>
    <div class="h4">{{ $t('EngravingTool.welcome') }}</div>

    <div class="duo-button engraving-modal__buttons duo-button--full">
      <div class="duo-button__wrapper">
        <button
          type="button"
          class="btn btn--default btn--even btn--outlined btn--full btn--icon"
          :data-tracking="getDataTracking('Enter your reference')"
          @click="setView({ view: views.ENTER_REFERENCE })"
        >
          <span class="btn__text" :data-description="$t('EngravingTool.defaultView.cta1Description')">
            {{ $t('EngravingTool.defaultView.cta1Title') }}
          </span>
          <span class="btn__icon">
            <phx-icon icon="arrow-16" bidirectional />
          </span>
        </button>

        <button
          type="button"
          class="btn btn--default btn--even btn--outlined btn--full btn--icon"
          :data-tracking="getDataTracking('Select a watch')"
          data-cy="select-watch"
          @click="setView({ view: views.SELECT_WATCH })"
        >
          <span class="btn__text" :data-description="$t('EngravingTool.defaultView.cta2Description')">
            {{ $t('EngravingTool.defaultView.cta2Title') }}
          </span>
          <span class="btn__icon">
            <phx-icon icon="arrow-16" bidirectional />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
